<template>
  <div>
    <v-tabs
      fixed-tabs
      height="80px"
      background-color="transparent"
      v-model="selectedTabLocal"
      show-arrows
      :slider-color="activeSliderColor ? 'primary' : 'transparent'"
      :color="activeSliderColor ? 'primary' : 'rgba(0, 0, 0, 0.54)'"
    >
      <v-tab
        id="tab-historico"
        @click="$emit('click:triggered'), changeTipoLista('historico')"
      >
        <span>
          <div>
            <v-icon class="icon-tab mb-2">mdi-history</v-icon>
          </div>
          Historico
        </span>
      </v-tab>
      <v-tab
        id="tab-favoritos"
        @click="
          $emit('click:triggered'),
            changeTipoLista('favoritos', 'Análises Setorizadas Favoritas')
        "
      >
        <span>
          <div>
            <v-icon class="icon-tab mb-2">mdi-star</v-icon>
          </div>
          Favoritas
        </span>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  props: {
    activeSliderColor: {
      type: Boolean,
      default: true
    },
    selectedTab: {
      type: Number,
      default: 0
    }
  },

  computed: {
    selectedTabLocal: {
      get() {
        return this.selectedTab;
      },
      set(newValue) {
        this.$emit('update:selectedTab', newValue);
      }
    }
  },

  methods: {
    changeTipoLista(tipo, titulo = 'Análises Setorizadas') {
      this.$store.dispatch('setTipoListaAnaliseSetorizada', {
        tipo: tipo,
        titulo: titulo
      });
    }
  }
};
</script>
